import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import SVG from 'react-inlinesvg'

import Section from '@components/Section'
import Sticky, { StickyState } from '@components/Sticky'
import HorizontalScroll from '@components/HorizontalScroll'
import media from '@styles/media'
import { clamp, useResize } from '@utils'

import AboutHeading from './About.Heading'

const BACKGROUND_COLOR_BLENDS = [
  '#1D2128',
  '#1C1F26',
  '#1A1E24',
  '#191C23',
  '#181B21',
  '#17191F',
  '#15181D',
  '#14161B',
  '#131519',
  '#121318',
  '#101216',
  '#0F1014',
]

const companyLogosQuery = graphql`
  {
    hopperLogo: file(name: { regex: "/testimonial-company-logo-hopper/" }) {
      publicURL
    }
    flowLogo: file(name: { regex: "/testimonial-company-logo-flow/" }) {
      publicURL
    }
    gatsbyLogo: file(name: { regex: "/testimonial-company-logo-gatsby/" }) {
      publicURL
    }
    netlifyLogo: file(name: { regex: "/testimonial-company-logo-netlify/" }) {
      publicURL
    }
  }
`

function AboutTestimonial() {
  const { hopperLogo, flowLogo, gatsbyLogo, netlifyLogo } = useStaticQuery(
    companyLogosQuery
  )
  const { height, width } = useResize()

  const testimonials = [
    {
      name: 'Mobile Solutions',
      title: 'IOS/ANDROID/HYBRID/PWA',
      testimonial:
        "Muse Zade can deliver you your own mobile applications for your business. Our clients have reached us to build their Grocery Apps, Saloon Apps, Delivery Apps and many such mobile applications",
      logo: gatsbyLogo,
    },
    {
      name: 'Web Development',
      title: 'Management Systems/WebGIS',
      testimonial:
        "Muse Zade can deliver your your own custom-made blazingly fast web solutions. We try to use the latest technologies for our projects but can be flexible if you wish a different tech stacks.",
      logo: netlifyLogo,
    },
    {
      name: 'Desktop Applications',
      title: 'POS/Management Systems',
      testimonial:
        'We provide you highly scalable desktop solutions as per your need along with performance profiling and benchmark reports for your custom made desktop solutions. ',
      logo: flowLogo,
    },
    {
      name: 'Ecommerce Solutions',
      title: 'Django-oscar/Shopify',
      testimonial:
        "We can provide you complete multi-vendor scalable ecommerce solutions with custom made design and features.",
      logo: hopperLogo,
    },
  ]

  return (
    <>
      <Desktop>
        <Sticky
          cover
          height={width < 767 ? `1800px` : `2600px`}
          render={({ progress }: StickyState) => {
            const four = progress * 2
            const textStyles =
              progress > 0
                ? ` style="display: block; opacity: ${1 -
                    four}; transform: scale(${1 -
                    progress / 4}); will-change: opacity, transform;"`
                : ``

            return (
              <AboutTestimonialContainer>
                <HeadingLineBreak>
                  <AboutHeading
                    heading="<span>What we are<br /> most proficient at!!</span>"
                    text={`<span style="color:#73737D">Press C if you want any of these solutions!!</span>`}
                  />
                </HeadingLineBreak>
                <Section narrow>
                  <TestimonialCardContainer>
                    {testimonials.map((testimonial, index) => {
                      const total = testimonials.length
                      const nextIndex = index + 1
                      const previousIndex = index - 1
                      const first = index === 0
                      const minZeroMaxOne = (num: number) => clamp(num, 0, 1)

                      const prevStaggered = minZeroMaxOne(
                        progress - previousIndex / total
                      )
                      const currentStaggered = minZeroMaxOne(
                        progress - index / total
                      )
                      const nextStaggered = minZeroMaxOne(
                        progress - nextIndex / total
                      )

                      const prevProgress = minZeroMaxOne(prevStaggered * total)
                      const currentProgress = minZeroMaxOne(
                        currentStaggered * total
                      )
                      const nextProgress = minZeroMaxOne(nextStaggered * total)

                      let offsetHeight = first ? 100 : 450

                      if (width < 460 && height < 750) {
                        offsetHeight = first ? 180 : 540
                      }

                      if (width < 460 && height < 668) {
                        offsetHeight = first ? 230 : 580
                      }

                      const transalteYFirst = currentProgress * offsetHeight

                      const transalteYSecond =
                        transalteYFirst +
                        nextProgress * 22 * (total - nextIndex)

                      const scaleCurve = 1 - nextStaggered * 0.25

                      const selectedBlend = Math.round(
                        ((((1 - scaleCurve) * 100) / 9) * 10) / 2
                      )

                      return (
                        <TestimonialCard
                          data-card={index}
                          key={testimonial.name}
                          style={{
                            transform: `translateY(-${transalteYSecond}px) scale(${scaleCurve})`,
                            opacity: prevProgress,
                            willChange: 'transform, opacity',
                          }}
                        >
                          <Card
                          >
                            <LogoContainer/>
                            <VerticalDivider />
                            <div>
                              <Title>
                                {testimonial.name} · {testimonial.title}
                              </Title>
                              <Text>{testimonial.testimonial}</Text>
                            </div>
                          </Card>
                        </TestimonialCard>
                      )
                    })}
                  </TestimonialCardContainer>
                </Section>
              </AboutTestimonialContainer>
            )
          }}
        />
      </Desktop>

      <Mobile>
        <HeadingLineBreak>
          <AboutHeading
            heading="Hire Us!!"
          />
        </HeadingLineBreak>
        <Section narrow>
          <TestimonialCardContainer>
            <HorizontalScroll
              list={testimonials}
              name="testimonial"
              narrow
              render={({ testimonial }: any) => {
                return (
                  <TestimonialCard key={testimonial.name} as="div">
                    <Card>
                      <VerticalDivider />
                      <div style={{ width: '100%' }}>
                        <Name>{testimonial.name}</Name>
                        <Title>{testimonial.title}</Title>
                        <Text>{testimonial.testimonial}</Text>
                      </div>
                    </Card>
                  </TestimonialCard>
                )
              }}
            />
          </TestimonialCardContainer>
        </Section>
      </Mobile>
    </>
  )
}

export default AboutTestimonial

const AboutTestimonialContainer = styled.div`
  position: relative;
  padding-top: 10vh;
  height: 100vh;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    height: 20vh;
    min-height: 200px;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 80%);
    pointer-events: none;

    @media (min-height: 800px) {
      height: 25vh;
      min-height: 300px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 60%);
    }

    @media (min-height: 950px) {
      height: 35vh;
      min-height: 400px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 50%);
    }

    @media (min-height: 1050px) {
      height: 40vh;
      min-height: 400px;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #090a0d 30%);
    }

    ${media.tablet`
      height: 20vh;
      min-height: 100px;
    `}

    ${media.phone_small`
      height: 10vh;
      min-height: auto;
      background: linear-gradient(180deg, rgba(11, 12, 15, 0) 0%, #0b0c0f 70%);
    `}
  }

  ${media.phone_small`
    padding-top: 5vh;
  `}
`

const TestimonialCardContainer = styled.ul`
  position: relative;
  max-width: 750px;
  margin: 90px auto 0;
  list-style: none;

  ${media.desktop`
    margin: 50px auto 0;
  `}
`

const TestimonialCard = styled.li`
  top: 350px;
  height: 350px;
  position: absolute;

  &:first-child {
    top: 0;
  }

  ${media.desktop`
    position: relative;
    top: 0;
    height: auto;
    margin-bottom: 20px;
  `}
`


const Card = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  background:
  -moz-linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #454545 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a1a1a1
);
background:
-webkit-linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #454545 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a1a1a1
);
background:
-o-linear-gradient(
  -72deg,
  #dedede,
  #ffffff 16%,
  #dedede 21%,
  #ffffff 24%,
  #454545 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a1a1a1
);
background:
linear-gradient(
  -72deg,
  #dedede,
  #ffffff 40%,
  #dedede 21%,
  #ffffff 24%,
  #454545 27%,
  #dedede 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedede 72%,
  #ffffff 80%,
  #dedede 84%,
  #a1a1a1
);
}

  padding: 40px 40px 40px 0;
  font-size: 18px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.35);
  transition: background 0.1s;

  ${media.tablet`
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: auto;
    padding: 30px 30px 0;
  `}

  ${media.phablet`
    padding: 30px 15px 0;
    min-height: 240px;
  `}
`

const LogoContainer = styled.div`
  ${media.tablet`
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.05);
    order: 3;
  `}
`

const Name = styled.div`
  font-weight: 700;
  color: black;
  white-space: pre-wrap;
`

const Title = styled.div`
  font-weight: 700;
  color: #124180;

  ${media.tablet`
  font-weight: 900;
    margin-bottom: 22px;
    white-space: pre-wrap;
  `}
`

const Text = styled.p`
  color: ${p => p.theme.colors.grey};

  ${media.tablet`
    padding-bottom: 30px;
  `}

  ${media.phablet`
    font-size: 16px;
    white-space: pre-wrap;
    padding-bottom: 20px;
  `}
`

const VerticalDivider = styled.div`
  height: 120px;
  width: 5px;
  margin-right: 40px;
  background: rgba(250, 250, 250, 0.05);

  ${media.tablet`
    display: none;
  `}
`

const HeadingLineBreak = styled.div`
  h2 {
    width: 70%;
    display: block;

    ${media.tablet`
      width: 80%;
    `}

    ${media.phone`
      width: 90%;
    `}
  }
`

const Desktop = styled.div`
  ${media.desktop`
    display: none;
  `}
`

const Mobile = styled.div`
  display: none;

  ${media.desktop`
    position: relative;
    display: block;
    margin: 100px auto 160px;
  `}

  ${media.phablet`
    margin: 100px auto;
  `}
`
